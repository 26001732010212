// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-success-jsx": () => import("./../src/pages/success.jsx" /* webpackChunkName: "component---src-pages-success-jsx" */),
  "component---src-pages-portfolio-copernicus-global-land-mdx": () => import("./../src/pages/portfolio/copernicus-global-land.mdx" /* webpackChunkName: "component---src-pages-portfolio-copernicus-global-land-mdx" */),
  "component---src-pages-portfolio-other-work-geonode-mdx": () => import("./../src/pages/portfolio/other-work/geonode.mdx" /* webpackChunkName: "component---src-pages-portfolio-other-work-geonode-mdx" */),
  "component---src-pages-portfolio-other-work-geospaca-mdx": () => import("./../src/pages/portfolio/other-work/geospaca.mdx" /* webpackChunkName: "component---src-pages-portfolio-other-work-geospaca-mdx" */),
  "component---src-pages-portfolio-other-work-personal-website-mdx": () => import("./../src/pages/portfolio/other-work/personal-website.mdx" /* webpackChunkName: "component---src-pages-portfolio-other-work-personal-website-mdx" */),
  "component---src-pages-portfolio-innometeo-mdx": () => import("./../src/pages/portfolio/innometeo.mdx" /* webpackChunkName: "component---src-pages-portfolio-innometeo-mdx" */),
  "component---src-pages-portfolio-emsa-tdms-mdx": () => import("./../src/pages/portfolio/emsa-tdms.mdx" /* webpackChunkName: "component---src-pages-portfolio-emsa-tdms-mdx" */),
  "component---src-pages-portfolio-other-work-qgis-conefor-plugin-mdx": () => import("./../src/pages/portfolio/other-work/qgis-conefor-plugin.mdx" /* webpackChunkName: "component---src-pages-portfolio-other-work-qgis-conefor-plugin-mdx" */),
  "component---src-pages-portfolio-other-work-qgis-python-training-course-mdx": () => import("./../src/pages/portfolio/other-work/qgis-python-training-course.mdx" /* webpackChunkName: "component---src-pages-portfolio-other-work-qgis-python-training-course-mdx" */),
  "component---src-pages-portfolio-other-work-ubuntu-freesound-scope-mdx": () => import("./../src/pages/portfolio/other-work/ubuntu-freesound-scope.mdx" /* webpackChunkName: "component---src-pages-portfolio-other-work-ubuntu-freesound-scope-mdx" */),
  "component---src-pages-portfolio-other-work-pycsw-mdx": () => import("./../src/pages/portfolio/other-work/pycsw.mdx" /* webpackChunkName: "component---src-pages-portfolio-other-work-pycsw-mdx" */),
  "component---src-pages-portfolio-save-my-bike-mdx": () => import("./../src/pages/portfolio/save-my-bike.mdx" /* webpackChunkName: "component---src-pages-portfolio-save-my-bike-mdx" */)
}

